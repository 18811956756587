
import { Options, Vue } from 'vue-class-component';

@Options({
    data(){
        return{
            data: {}
        }
    },
    methods: {
        handlerChange(e: any){
            console.log(e)
        }
    }
})

export default class Home extends Vue {}
